import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { Reset } from 'styled-reset'
import 'react-loading-skeleton/dist/skeleton.css'

import logo from './img/logo.png'
import logoWebp from './img/logo.webp'
import UserProvider from './Context/UserContext'

import GlobalFonts from './styles/fonts/fonts'
import GlobalStyle from './styles/GlobalStyle'
import { useLang } from './Context/LangContext'
import PrivateRoute from './Component/PrivateRoute'

// import SignUpLoading from './Layout/SignUpLoading'
import SignUp from './Layout/SignUp'
import EnterCoupon from './Layout/EnterCoupon'
import Success from './Layout/Success'
// const SignUp = React.lazy(() => import('./Layout/SignUp'))

// const EnterCoupon = React.lazy(() => import('./Layout/EnterCoupon'))
// const Success = React.lazy(() => import('./Layout/Success'))

const Container = styled.div`
  font-family: 'Roboto', sans-serif;
  height: 640px;
  width: 100%;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 25.78px;
  :lang(ar) {
    font-family: 'Noto Sans Arabic UI';
    -webkit-rtl-ordering: visual;
  }
`
const Footer = styled.footer`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 149px;
    height: 41;
  }
`

export default function App() {
  const { lang } = useLang()
  return (
    <>
      <GlobalFonts />
      <GlobalStyle />
      <Reset />
      <UserProvider>
        <BrowserRouter>
          <Container lang={lang}>
            <Switch>
              <Route
                path="/redeem"
                render={() => (
                  <BrowserRouter basename="/redeem">
                    <Switch>
                      <PrivateRoute path="/coupon" component={EnterCoupon} />
                      <PrivateRoute path="/success" component={Success} />
                      {/* <Route path="/coupon" component={EnterCoupon} />
                  <Route path="/success" component={Success} /> */}
                      <Route path="/" component={SignUp} />
                      {/* <Route
                        path="/"
                        render={() => (
                          <Suspense fallback={<SignUpLoading />}>
                            <SignUp />
                          </Suspense>
                        )}
                      /> */}
                    </Switch>
                  </BrowserRouter>
                )}
              />
              <Route path="/" render={() => <Redirect to="/redeem" />} />
            </Switch>

            <Footer>
              <picture>
                <source srcSet={logoWebp} type="image/webp" />
                <img src={logo} />
              </picture>
            </Footer>
          </Container>
        </BrowserRouter>
      </UserProvider>
    </>
  )
}
