import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          'signin.1': 'Sign in to mojitok',
          'signin.2': '',
          'enter.code':
            'Enter your redemption code to access mojitok stickers!',
          continue: 'CONTINUE',
          'coupon.error': 'The redemption code you entered is invalid.',
          'success.title.1': 'Your subscription has been activated!',
          'success.title.2': '',
          'success.description':
            'Thank you for subscribing to mojitok. Your subscription will expire on YY/MM/DD. Please log in on the app to start using mojitok stickers!',
          coupon: {
            error: {
              default: 'The redemption code you entered is invalid.',
              409: 'You already have an active coupon',
              server: 'Please try later',
            },
          },
        },
      },
      ar: {
        translation: {
          'signin.1': 'ﻞﻴﺠﺴﺘﺑ ﻢﻗ',
          'signin.2': 'mojitok ﻊﻣ لﻮﺧﺪﻟا',
          'enter.code': 'كاﺮﺘﺷﺎﻟا ﺢﺘﻔﻟ ﻚﺑ صﺎﺨﻟا دادﺮﺘﺳﺎﻟا ﺰﻣر ﻞﺧدأ',
          continue: 'رمتسا',
          'coupon.error': 'The redemption code you entered is invalid.',
          'success.title.1': 'ﻲﻬﺘﻨﻨﻳ فﻮﺳ',
          'success.title.2': '.مﺎﻳا ٣ ﻲﻓ ﻚﻛاﺮﺘﺷا',
          'success.description':
            'Thank you for subscribing to mojitok. Your subscription will expire on YY/MM/DD. Please log in on the app to start using mojitok stickers!',
          coupon: {
            error: {
              default: '.ﺢﻟﺎﺻ ﺮﻴﻏ ﻪﺘﻠﺧدا يﺬﻟا دادﺮﺘﺳﺎﻟا ﺰﻣر',
              409: 'You already have an active coupon',
              server: 'Please try later',
            },
          },
        },
      },
    },
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
