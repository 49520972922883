import axios from 'axios'
import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components'
import LoadingCirecle from '../Component/LoadingCircle'
import { useLang } from '../Context/LangContext'
import { useUser } from '../Context/UserContext'
type Status = 'pending' | 'finish'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  text-align: center;
  max-width: 310px;
  width: 90%;
  min-height: 52px;
  font-size: 22px;
  margin-top: 144px;
  font-style: normal;
  font-weight: normal;
  :lang(ar) {
    line-height: 29.96px;
  }
`
const Input = styled.input`
  margin-top: 61px;
  max-width: 290px;
  width: 90%;
  height: 45px;
  border: none;

  /* Caption surface */
  background: #f5f5f5;
  border-radius: 11px;
  font-size: 22px;
  line-height: 26px;

  /* identical to box height */
  text-align: center;

  /* Tab text */
  color: rgba(0, 0, 0, 0.87);
`
const Button = styled.button<{ active: boolean; state: Status }>`
  margin: 37px;
  width: 290px;
  height: 46px;
  background: #3278ff;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  border: none;
  letter-spacing: 1.25px;
  /* Background */
  color: #ffffff;
  cursor: pointer;
  :lang(ar) {
    font-weight: 700;
  }
  ${({ active }) =>
    !active &&
    css`
      background-color: rgba(0, 0, 0, 0.3);
    `}
  ${({ state }) =>
    state === 'pending' &&
    css`
      cursor: not-allowed;
      background-color: rgba(0, 0, 0, 0.3);
    `}
    :lang(ar) {
    font-family: 'Noto Sans Arabic UI';
    -webkit-rtl-ordering: visual;
  }
`
const ErrorMessage = styled.div`
  margin-top: 5px;
  width: 281px;
  min-height: 14px;
  font-size: 14px;

  /* identical to box height, or 14px */
  letter-spacing: 0.25px;
  /* Error */
  color: #ff3b30;
`
export default function EnterCoupon() {
  const { lang } = useLang()
  const history = useHistory()
  const { t } = useTranslation()
  const {
    userData: { user },
  } = useUser()
  const [code, setCode] = useState('')
  const [state, setState] = useState<Status>('finish')
  const [error, setError] = useState('')
  const handleClick = async () => {
    setState('pending')
    const token = await user?.getIdToken()
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json',
      },
    }
    try {
      const response = await axios.post(
        'https://bookeey.mojitok-api.com/user/coupons/register',
        {
          couponCode: code,
        },
        config,
      )
      history.push({
        pathname: '/success',
        state: {
          expireDate: response.data.data.calculatedExpirationDate,
        },
      })
      history.push('/success')
    } catch (error: any) {
      // setError('The redemption code you entered is invalid. ')
      // console.dir(error)
      // console.dir(error.response.data.error)

      const responseStatus = error.response.status
      const isServerError = Math.floor(responseStatus / 100) * 100 === 500

      if (error.response.data.status === 409) {
        setError(t('coupon.error.409'))
      } else if (isServerError) {
        setError(t('coupon.error.server'))
      } else {
        setError(t('coupon.error.default'))
      }
    } finally {
      setState('finish')
    }
  }
  useLayoutEffect(() => {
    document.title = 'mojitok Code Redemption'
  }, [])
  return (
    <Container>
      <Title lang={lang}>{t('enter.code')}</Title>
      <Input
        value={code}
        onChange={(e) => {
          setCode(e.target.value)
        }}
      />
      {state === 'finish' && error && <ErrorMessage>{error}</ErrorMessage>}
      <Button
        lang={lang}
        onClick={handleClick}
        state={state}
        active={code === '' ? false : true}
      >
        {state === 'pending' ? (
          <LoadingCirecle size={16} />
        ) : (
          <>{t('continue')}</>
        )}
      </Button>
    </Container>
  )
}
