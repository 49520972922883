import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth'

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAzfgiHmskMrYm69efCgj0bfkslVHeCLMY',
  authDomain: 'mojitok-v3.firebaseapp.com',
  projectId: 'mojitok-v3',
  storageBucket: 'mojitok-v3.appspot.com',
  messagingSenderId: '291770148796',
  appId: '1:291770148796:web:130cbc7dec7454e0bc2db9',
  measurementId: 'G-83D00TQEHQ',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
export default firebaseApp

setPersistence(getAuth(), browserSessionPersistence).catch((error) => {
  // Handle Errors here.
  const errorCode = error.code
  const errorMessage = error.message
  console.log(error)
})
// firebase
//   .auth()
//   .setPersistence(firebase.auth.Auth.Persistence.SESSION)
//   .then(() => {
//     // var provider = new firebase.auth.GoogleAuthProvider();
//     // In memory persistence will be applied to the signed in Google user
//     // even though the persistence was set to 'none' and a page redirect
//     // occurred.
//     // return firebase.auth().signInWithRedirect(provider);
//   })
//   .catch((error) => {
//     // Handle Errors here.
//     var errorCode = error.code
//     var errorMessage = error.message
//     console.log(error)
//   })// Configure Firebase.
// const config = {
//   apiKey: 'AIzaSyAzfgiHmskMrYm69efCgj0bfkslVHeCLMY',
//   authDomain: 'mojitok-v3.firebaseapp.com',
//   projectId: 'mojitok-v3',
//   storageBucket: 'mojitok-v3.appspot.com',
//   messagingSenderId: '291770148796',
//   appId: '1:291770148796:web:130cbc7dec7454e0bc2db9',
//   measurementId: 'G-83D00TQEHQ',
// }
// firebase.initializeApp(config)
