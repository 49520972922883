import { createContext, useContext, useEffect, useState } from 'react'
import { User, onAuthStateChanged, getAuth } from 'firebase/auth'
import firebaseApp from '../firebase'

type UserData = {
  user: null | User
  firebaseReady: boolean
}
const UserContext = createContext<{
  userData: UserData
}>({
  userData: { user: null, firebaseReady: false },
})
export const useUser = () => {
  return useContext(UserContext)
}

export default function UserProvider({ children }: any) {
  const [userData, setUserData] = useState<UserData>({
    user: null,
    firebaseReady: false,
  })
  useEffect(() => {
    onAuthStateChanged(getAuth(firebaseApp), (usr) => {
      // console.log('auth change', usr)
      if (usr) {
        setUserData({ user: usr, firebaseReady: true })
      } else {
        setUserData({ user: null, firebaseReady: true })
      }
    })
  }, [])

  return (
    <UserContext.Provider value={{ userData }}>{children}</UserContext.Provider>
  )
}
