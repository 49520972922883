import { createContext, useContext, useLayoutEffect, useState } from 'react'
import i18n from '../i18'
type Lang = 'en' | 'ar'
const LangContext = createContext<{
  lang: Lang
}>({
  lang: 'en',
})
export const useLang = () => {
  return useContext(LangContext)
}

export default function LangProvider({ children }: any) {
  const [lang, setLang] = useState<Lang>('en')
  useLayoutEffect(() => {
    if (/^ar\b/.test(navigator.language)) {
      i18n.changeLanguage('ar')
      setLang('ar')
    }
  }, [])
  return (
    <LangContext.Provider
      value={{
        lang,
      }}
    >
      {children}
    </LangContext.Provider>
  )
}
