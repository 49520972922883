import { createGlobalStyle } from 'styled-components'
import roboto400 from './roboto-v29-latin-regular.woff2'
import roboto500 from './roboto-v29-latin-500.woff2'
import NotoSansArabicUIRegular from './NotoSansArabicUI-Regular.ttf'
import NotoSansArabicUISemiBold from './NotoSansArabicUI-SemiBold.ttf'
import NotoSansArabicUIBold from './NotoSansArabicUI-Bold.ttf'

export default createGlobalStyle`
    @font-face {
        font-family: "Roboto";
        src: local('Roboto') url(${roboto400}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        src: local('Roboto Medium') url(${roboto500}) format('woff2');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Noto Sans Arabic UI";
        src: url(${NotoSansArabicUIRegular}) format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Noto Sans Arabic UI";
        src: url(${NotoSansArabicUISemiBold}) format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Noto Sans Arabic UI";
        src: url(${NotoSansArabicUIBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    
`
