import { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { useUser } from '../Context/UserContext'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router'
import { useLang } from '../Context/LangContext'
import LoadingCircle from '../Component/LoadingCircle'
import { getApp } from 'firebase/app'
import firebaseApp from '../firebase'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  font-size: 34px;
  margin-top: 180px;
  margin-bottom: 46px;
  max-width: 300px;
  text-align: center;
  :lang(ar) {
    font-size: 34px;
    line-height: 46px;
  }
`
const Load = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    z-index: 11;
  }
`

const FirebaseContainer = styled.div<{ ready: boolean }>`
  ${(ready) =>
    !ready &&
    css`
      display: none;
    `}
`

export default function SignUp() {
  const [loading, setloading] = useState(false)
  const [signInSuccess, setSignInSuccess] = useState(false)
  const [firebaseUiShow, setFirebaseUiShow] = useState(false)
  const { lang } = useLang()
  const history = useHistory()

  const { t } = useTranslation()
  const { userData } = useUser()
  //firebase 가 a모바일에서 느림
  // entercoupon페이지로 갔는데 유저가 없어서 redirect 될수 있음
  useEffect(() => {
    // console.log('usedata???', userData)
    if (userData.user && userData.firebaseReady === true && signInSuccess) {
      history.push('/coupon')
    }
  }, [userData, signInSuccess])
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    // signInSuccessUrl: '/coupon',
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: 'select_account',
        },
      },

      'apple.com',
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => {
        // console.log('sign in success')
        setSignInSuccess(true)
        return false
      },
      uiShown: () => {
        setFirebaseUiShow(true)
      },
      signInFailure: function (error: any) {
        // Some unrecoverable error occurred during sign-in.
        // Return a promise when error handling is completed and FirebaseUI
        // will reset, clearing any UI. This commonly occurs for error code
        // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
        // occurs. Check below for more details on this.
        // console.log(error)
        setloading(false)
      },
    },
  }
  useEffect(() => {
    const windowOpen = window.open
    const handleClick = () => {
      setloading(false)
    }
    window.open = (...prop) => {
      setloading(true)
      window.document.body.addEventListener('click', handleClick)
      return windowOpen(...prop)
    }
    return () => {
      window.open = windowOpen
      window.document.body.removeEventListener('click', handleClick)
    }
  }, [])
  return (
    <Container>
      {loading && (
        <Load>
          <LoadingCircle
            style={{ stroke: 'black', strokeWidth: '3px' }}
            size={40}
          />
        </Load>
      )}
      <Title lang={lang}>
        <p>{t('signin.1')}</p>
        <p>{t('signin.2')}</p>
      </Title>

      {userData.firebaseReady === false ||
        (!firebaseUiShow && (
          <>
            <Skeleton
              style={{
                maxWidth: '220px',
                minHeight: '40px',
                width: '220px',
                marginBottom: '15px',
                marginTop: '16px',
              }}
            />
            <Skeleton
              style={{
                maxWidth: '220px',
                minHeight: '40px',
                width: '220px',
              }}
            />
          </>
        ))}
      <FirebaseContainer
        ready={userData.firebaseReady === true && firebaseUiShow}
      >
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </FirebaseContainer>
    </Container>
  )
}
