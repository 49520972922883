import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
 
  body {
  }

  
`

export default GlobalStyle
