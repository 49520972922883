import { Redirect, Route } from 'react-router-dom'
import { useUser } from '../Context/UserContext'

export default function PrivateRoute({
  path,
  component,
}: {
  path: string
  component: React.ComponentType<any>
}) {
  const {
    userData: { user, firebaseReady },
  } = useUser()
  return (
    <>
      {user === null && firebaseReady === true && <Redirect to="/" />}
      {user !== null && firebaseReady === true && (
        <Route path={path} component={component} />
      )}
    </>
  )
}
