import { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useLang } from '../Context/LangContext'
import { ReactComponent as DownloadAppStore } from '../svg/download_app_store.svg'
import { ReactComponent as DownloadGoogleStore } from '../svg/download_google_store.svg'
import { ReactComponent as DownloadGoogleStoreAr } from '../svg/download_google_store_ar.svg'
import { ReactComponent as DownloadAppStoreAr } from '../svg/download_app_store_ar.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Title = styled.div`
  margin-top: 114px;
  width: 260px;
  min-height: 52px;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  :lang(ar) {
    font-weight: 600;
    line-height: 29.96px;
  }
`
const Description = styled.div`
  margin-top: 33px;
  width: 100%;
  max-width: 339px;
  font-size: 22px;
  text-align: center;
  -webkit-rtl-ordering: logical;
  margin-bottom: 26px;
`

const IconWrapper = styled.div`
  margin-top: 28px;
`
export default function Success() {
  const { lang } = useLang()

  const { t } = useTranslation()
  const { location } = useHistory<any>()
  const [expireDate, setExpireDate] = useState<string>('')
  useEffect(() => {
    if (location.state?.expireDate) {
      const date = new Date(location.state.expireDate)
      const expireDateString =
        date.getFullYear().toString().substr(-2) +
        '/' +
        (date.getMonth() + 1).toString() +
        '/' +
        date.getDate().toString()
      setExpireDate(expireDateString)
    }
  }, [])
  useLayoutEffect(() => {
    document.title = 'Welcome to mojitok!'
  }, [])
  return (
    <Container>
      <Title lang={lang}>
        <p>{t('success.title.1')}</p>
        <p>{t('success.title.2')}</p>
      </Title>
      <Description>
        {t('success.description').replace('YY/MM/DD', expireDate)}
      </Description>
      <IconWrapper
        onClick={() => {
          window.open(
            'https://play.google.com/store/apps/details?id=com.mojitok.app.bookeey2',
          )
        }}
      >
        {lang === 'ar' ? <DownloadGoogleStoreAr /> : <DownloadGoogleStore />}
      </IconWrapper>
      <IconWrapper
        onClick={() => {
          window.open(
            'https://apps.apple.com/us/app/mojitok-gif-stickers-for-chat/id1588851588',
          )
        }}
      >
        {lang === 'ar' ? <DownloadAppStoreAr /> : <DownloadAppStore />}
      </IconWrapper>
    </Container>
  )
}
