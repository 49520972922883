import React from 'react'
import styled, { keyframes } from 'styled-components'
const loading = keyframes` 
0% {
    transform: rotate(0deg);
}
100%{
  transform: rotate(360deg);
}
  `
const Svg = styled.svg`
  opacity: 1;
  animation: 0.7s linear infinite ${loading};
  fill: none;
  stroke: #42526e;
  stroke-width: 1.5px;
  stroke-linecap: round;
  stroke-dasharray: 60px;
  stroke-dashoffset: 30px;
`
export default function LoadingCirecle({
  size,
  style,
}: {
  size: number
  style?: React.CSSProperties
}) {
  return (
    <Svg
      style={style}
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={size / 2} cy={size / 2} r={size / 2 - 1}></circle>
    </Svg>
  )
}
